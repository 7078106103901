@import 'assets/styles/variables';

.wrapper {
  align-items: center;
  background-color: $main-white;
  display: flex;
  height: 100vh;
  justify-content: center;
  width: 100vw;

  &-dark {
    background-color: $main-black;
  }
}
