@import 'assets/styles/variables';

.main {
  align-items: center;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  padding-top: 130px;

  &-dark {
    background-color: $main-black;
    color: $main-white;
  }
}

.container {
  align-items: center;
  color: $main-black;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: calc(100vh - #{$header-height});
  padding-top: 50px;

  form {
    margin: 0 auto;
    max-width: 338px;
  }

  .additional {
    margin: 50px 0 44px;
  }

  .logout {
    align-items: center;
    background: transparent;
    border-style: none;
    color: $main-black;
    cursor: pointer;
    display: flex;
    font-family: inherit;
    font-size: 18px;
    font-weight: 700;
    justify-content: space-between;
    justify-self: end;
    line-height: 18px;
    margin: auto auto 0;
    width: 101px;

    &-dark {
      color: $main-white;
    }
  }
}
