@import 'assets/styles/variables';

.spinner {
  display: block;
  height: 80px;
  margin: 0 auto;
  position: relative;
  width: 80px;

  div {
    animation: spinner 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border: 8px solid $main-black;
    border-color: $main-black transparent transparent transparent;
    border-radius: 50%;
    box-sizing: border-box;
    display: block;
    height: 64px;
    position: absolute;
    width: 64px;
  }

  div:nth-child(1) {
    animation-delay: -0.45s;
  }

  div:nth-child(2) {
    animation-delay: -0.3s;
  }

  div:nth-child(3) {
    animation-delay: -0.15s;
  }
}
 
@keyframes spinner {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}
