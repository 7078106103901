@import 'assets/styles/variables';
@import 'assets/styles/mixins';

.save {
  @include subheading-secondary();
  background: transparent;
  border-style: none;
  color: $main-black;
  cursor: pointer;

  &-dark {
    color: $main-white;
  }
}

.cancel {
  background: transparent;
  border-style: none;
  cursor: pointer;
}

.loader {
  display: flex;
  justify-content: center;
  margin-right: 12px;
}
