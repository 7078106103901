@import 'assets/styles/variables';
@import 'assets/styles/mixins';

.title {
  color: $text-pure-black;
  font-size: 28px;
  font-weight: 700;
  letter-spacing: 0.03em;
  line-height: 28px;
  margin-bottom: 40px;
  text-align: center;
}

.form {
  position: relative;
  width: 100%;
}

.error {
  height: 34px;
  line-height: 34px;
  padding-left: 32px;

  &__submit {
    line-height: 16px;
    padding-top: 5px;
    position: absolute;
    text-align: center;
  }
}
