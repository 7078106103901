.wrapper {
  align-items: center;
  display: flex;
  height: 100vh;
  padding: 75px 220px;
  position: relative;
  width: 100vw;
}

.close {
  background: none;
  border-style: none;
  cursor: pointer;
  outline: none;
  position: absolute;
  right: 75px;
  top: 75px;
  z-index: 10;
}

.navigation {
  background: transparent;
  border-style: none;
  bottom: 0;
  cursor: pointer;
  height: 50px;
  margin: auto;
  padding: 0;
  position: absolute;
  top: 0;
  width: 50px;
  z-index: 100;

  svg {
    height: 100%;
    width: 100%;
  }

  &:hover {
    height: 55px;
    width: 55px;
  }

  &:first-of-type {
    left: -90px;
  }

  &:last-of-type {
    right: -90px;
  }

  &:disabled {
    cursor: not-allowed;
    opacity: 0.2;
  }
}

@media screen and (max-width: 900px) {
  .wrapper {
    padding: 0;
  }

  .close {
    align-items: center;
    background-color: rgba(37, 37, 37, 0.2);
    border-radius: 50%;
    display: flex;
    height: 35px;
    justify-content: center;
    padding: 0;
    right: 20px;
    top: 20px;
    width: 35px;

    svg {
      height: 30px;
      width: 30px;
    }

    &-dark {
      background-color: rgba(255, 255, 255, 0.2);
    }
  }

  .navigation {
    &:first-of-type {
      left: 20px;
    }

    &:last-of-type {
      right: 20px;
    }
  }
}
