.container {
  margin: 0 auto;
  max-width: 1300px;
  width: 100%;
}

@media screen and (max-width:1339px) {
  .container {
    padding-left: 20px;
    padding-right: 20px;
  }
}
