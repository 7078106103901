@import 'assets/styles/variables';
@import 'assets/styles/mixins';

.wrapper {
  display: flex;
  margin-bottom: 70px;

  &-loading {
    align-items: center;
    min-height: calc(100vh - #{$header-height});
  }
}

.info {
  align-items: center;
  display: flex;
  justify-content: center;
  width: 50%;

  .content {
    align-items: center;
    color: $text-pure-black;
    display: flex;
    flex-direction: column;

    &-dark {
      color: $main-white;
    }

    &__date {
      @include subheading-secondary();
      margin-bottom: 20px;
      text-align: center;
    }

    &__title {
      @include text-36-bold-caps();
      margin-bottom: 20px;
    }
  }
}

.image {
  margin-top: -$header-height;
  min-height: 714px;
  overflow: hidden;
  width: 50%;

  &__img {
    height: 714px;
    min-width: 100%;
    object-fit: cover;
  }
}

@media screen and (max-width: 768px) {
  .wrapper {
    align-items: flex-start;
    margin-bottom: 20px;
    min-height: calc(568px - #{$header-height});
  }

  .info {
    padding-top: 146px;
    position: absolute;
    width: 100%;
    z-index: 10;

    .content {
      color: $main-white;
      text-shadow: 0 2px 4px #7f7f7f;

      > div svg path {
        fill: $main-white;
      }

      > div span {
        color: $main-black !important;
        text-shadow: none;
      }
    }
  }

  .image {
    height: 568px;
    margin-top: -$header-height;
    min-height: 568px;
    // position: absolute;
    width: 100%;

    &__img {
      height: 568px;
      min-width: 100%;
      object-fit: cover;
    }
  }
}
