@import 'assets/styles/mixins';
@import 'assets/styles/variables';

.container {
  display: flex;
  min-height: 100vh;
}

.design {
  align-items: center;
  background-image: url('../../../assets/images/auth-gradient.svg');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  color: $main-white;
  display: flex;
  flex-direction: column;
  padding-top: 100px;
  position: relative;
  width: 50%;

  &__photos {
    left: 8px;
    position: absolute;
    top: 233px;
    width: 111%;
  }
}

.form {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding-bottom: 50px;
  padding-top: 115px;
  position: relative;
  width: 50%;

  &-centered {
    justify-content: center;
    padding-top: 0;

    p {
      margin-bottom: 78px;
    }
  }

  .back {
    background: transparent;
    border-style: none;
    color: transparent;
    cursor: pointer;
    height: 22px;
    left: 31px;
    padding: 0;
    position: absolute;
    top: 35px;
    width: 22px;
    z-index: 100;
  }

  &__wrapper {
    margin: 0 auto;
    max-width: 338px;
    width: 100%;
  }
}

.sign {
  @include caption-regular();
  text-align: center;

  a {
    color: $main-black;
    font-weight: 700;
    text-decoration: none;
  }
}

@media screen and (max-width: 1439px) {
  .design {
    &__photos {
      left: 3px;
    }
  }
}

@media screen and (max-width: 900px) {
  .container {
    flex-direction: column;
  }

  .design {
    background-position: top;
    padding: 64px 0 94px;
    width: 100%;

    figure {
      padding-left: 0;
    }

    &__photos {
      display: none;
    }
  }

  .form {
    background-color: $main-white;
    border-top-left-radius: 40px;
    border-top-right-radius: 40px;
    margin-top: -40px;
    padding: 24px 0 34px;
    width: 100%;
    z-index: 10;

    &-centered {
      h1 {
        margin-bottom: 60px;
      }

      p {
        margin-bottom: 40px;
      }
    }

    .back {
      top: 26px;
    }
  }
}
