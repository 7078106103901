@import 'assets/styles/variables';
@import 'assets/styles/mixins';
@import 'assets/styles/functions';

.button {
  @include text-18-bold-caps();
  background: $main-black;
  border-radius: 50px;
  border-style: none;
  color: $main-white;
  cursor: pointer;
  line-height: 46px;
  outline: none;
  padding: 12px 79px;
  width: 100%;

  &:disabled {
    cursor: not-allowed;
  }

  &:hover:enabled {
    background: $button-hover-light-mode;
    opacity: 0.9;
  }

  &:active {
    background: $button-hover-light-mode;
  }

  &-icon {
    align-items: center;
    background: transparent;
    border: 3px solid $main-black;
    color: transparent;
    display: inline-flex;
    justify-content: center;
    max-width: 157px;
    padding: 21px 0;
    width: 100%;

    &:hover:enabled {
      background: transparent;
      opacity: 0.9;
    }
  }

  &-icon-title {
    position: relative;

    svg {
      bottom: 0;
      left: 19%;
      margin: auto 0;
      position: absolute;
      top: 0;
    }

    &--medium {
      max-width: 215px;
    }
  }

  &-light {
    background: black-opacity(0.08);
    color: $main-black;
    transition: all 0.2s ease-in;

    &:hover:enabled {
      background: $main-black;
      color: $main-white;

      svg {
        path {
          fill: $main-white;
        }
      }
    }

    &:active {
      background: $button-hover-light-mode;
    }

    &:disabled {
      opacity: 0.2;
    }
  }

  &-dark {
    background: $main-white-opacity;
    transition: all 0.2s ease-in;

    &:hover:enabled {
      background: $main-white;
      color: $main-black;

      svg {
        path {
          fill: $main-black;
        }
      }
    }

    &:disabled {
      opacity: 0.2;
    }

    &:active {
      background: $button-hover-dark-mode;
    }
  }

  &-small {
    line-height: 18px;
    padding: 16px;
    width: 130px;
  }

  &-big {
    max-width: 338px;

    &:hover:enabled {
      opacity: 0.8;
    }

    &--dark {
      background: $main-white;
      color: $main-black;

      &:active {
        background: $button-hover-dark-mode;
      }
    }
  }

  &-medium {
    line-height: 18px;
    max-width: 180px;
    padding: 16px;
  }

  &-shadowed {
    box-shadow: 0 10px 50px -20px rgba(37, 37, 37, 0.35);
  }
}

@media screen and (max-width: 425px) {
  .button {
    &-icon-title {
      background-color: $main-black;
      font-size: 0;
      height: 70px;
      padding: 0;
      width: 70px;

      svg {
        left: 0;
        margin: auto;
        right: 0;

        path {
          fill: $main-white;
        }
      }

      &-dark {
        background-color: $main-white;
        color: $main-black;

        svg {
          path {
            fill: $main-black;
          }
        }
      }
    }
  }
}
