@import 'assets/styles/variables';
@import 'assets/styles/mixins';

.container {
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin-bottom: 50px;

  .title {
    color: $text-pure-black;
    font-size: 28px;
    font-weight: 700;
    line-height: 28px;

    &-dark {
      color: $main-white;
    }
  }

  button {
    padding: 2px 20px 2px 54px;

    svg {
      left: 20px;
    }
  }

  .loading {
    height: 50px;
    width: 215px;
  }
}

.add {
  display: none;
}

@media screen and (max-width: 800px) {
  .container {
    display: none;
  }

  .add {
    align-items: center;
    background-color: $main-white;
    bottom: 0;
    display: flex;
    height: 74px;
    justify-content: center;
    position: fixed;
    width: 100%;
    z-index: 1000;

    .button {
      @include subheading-primary();
      align-items: center;
      background: transparent;
      border-style: none;
      cursor: pointer;
      display: flex;
      height: 74px;
      justify-content: space-between;
      outline: none;
      padding: 0;
      width: 122px;

      &:hover:enabled {
        opacity: 0.9;
      }

      &:disabled {
        cursor: not-allowed;
        opacity: 0.2;
      }
    }

    .save {
      display: block;
    }

    &-dark {
      background-color: $main-black;

      button {
        color: $main-white;
      }
    }
  }
}
