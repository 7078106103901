@import 'assets/styles/variables';
@import 'assets/styles/mixins';

.wrapper {
  padding-top: $header-height;

  &-loading {
    align-items: center;
    display: flex;
  }
}

.button {
  background: none;
  border-style: none;
  color: $main-black;
  cursor: pointer;
  line-height: 35px;
  text-decoration: none;
  @include subheading-primary();

  &:disabled {
    cursor: not-allowed;
    opacity: 0.2;
  }

  &-dark {
    color: $main-white;
  }
}

.modal {
  border: 1px dashed $gray-inactive-input;
  border-radius: 12px;
  margin-bottom: 38px;
  padding: 40px 25px 32px;

  &__title {
    @include title-28-bold();
    margin-bottom: 37px;
  }

  &__subtitle {
    @include text-18-bold();
    text-align: center;
  }

  &__buttons {
    display: flex;
    justify-content: center;
    padding-top: 37px;
    width: 100%;

    button:first-of-type {
      margin-right: 25px;
    }
  }
}

@media screen and (max-width: 800px) {
  .wrapper {
    padding-bottom: 74px;
  }

  .add {
    display: flex;
  }

  .save {
    &-cover {
      display: none;
    }

    &__small {
      display: block;
    }
  }

  .heading {
    visibility: hidden;

    &-cover {
      font-size: 16px;
      line-height: 16px;
      visibility: visible;
    }
  }
}

@media screen and (max-width: 600px) {
  .save {
    line-height: 18px;
  }
}
