.list {
  display: grid;
  grid-column-gap: 35px;
  grid-row-gap: 35px;
  grid-template-columns: repeat(auto-fit, minmax(406px, 1fr));
  justify-items: center;
  margin: 0 auto;
  padding: 3px 4px;

  &-small {
    grid-column-gap: 30px;
    grid-row-gap: 30px;
    grid-template-columns: repeat(auto-fill, minmax(160px, 1fr));
    justify-items: center;
  }
}

@media screen and (max-width: 800px) {
  .list {
    grid-column-gap: 20px;
    grid-row-gap: 20px;
    grid-template-columns: repeat(auto-fill, minmax(114px, 1fr));
    padding-bottom: 50px;
  }
}

@media screen and (max-width: 768px) {
  .list {
    &-small {
      grid-column-gap: 20px;
      grid-row-gap: 20px;
      grid-template-columns: repeat(auto-fill, minmax(114px, 1fr));
      justify-items: center;
    }
  }
}
