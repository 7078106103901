@import 'assets/styles/variables';
@import 'assets/styles/mixins';

.wrapper {
  margin-bottom: 14px;
  max-height: 74px;
  position: relative;

  &-avatar {
    margin-bottom: 50px;
  }

  &:last-of-type {
    margin-bottom: 0;
  }
}

.input {
  @include text-18-bold-caps();
  appearance: none;
  background-color: transparent;
  border: 1px solid $gray-inactive-input;
  border-radius: 50px;
  box-sizing: border-box;
  font-family: 'Sulphur Point', sans-serif;
  line-height: 32px;
  outline: none;
  padding: 18px 30px 18px 62px;
  text-transform: none;
  width: 100%;

  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus,
  &:-webkit-autofill:active,
  &:-webkit-autofill-strong-password,
  &:-webkit-autofill-strong-password-viewable {
    background-color: $main-white !important;
    border-color: $main-black !important;
    box-shadow: 0 0 0 1000px $main-white inset !important;
    color: $main-black !important;
    -webkit-text-fill-color: $main-black;
  }

  &:-webkit-autofill-strong-password:focus,
  &:-webkit-autofill-strong-password-viewable:focus {
    border: 1px solid transparent !important;
    border-color: $main-black !important;
    box-shadow: 0 0 0 3px $main-black, 0 0 0 1000px $main-white inset !important;
  }

  &:-internal-autofill-selected,
  &:-internal-autofill-previewed {
    box-shadow: 0 0 0 1000px $main-white inset !important;
    font-size: 18px !important;
  }

  &:-internal-autofill-selected:focus,
  &:-internal-autofill-previewed:focus {
    border: 1px solid transparent !important;
    box-shadow: 0 0 0 3px $main-black, 0 0 0 1000px $main-white inset !important;
  }

  &:hover {
    border: 1px solid $main-black;
  }

  &:focus {
    border-color: transparent;
    box-shadow: 0 0 0 3px $main-black;
  }

  &-dark {
    background-color: transparent;
    border: 1px solid $gray-inactive-input;
    color: $main-white;

    &:hover {
      border: 1px solid $main-white;
    }

    &:focus {
      background-color: $main-black;
      border-color: transparent;
      box-shadow: 0 0 0 3px $main-white;
      color: $main-white;
    }

    &:active {
      background-color: $main-black;
    }

    &:-webkit-autofill,
    &:-webkit-autofill:hover,
    &:-webkit-autofill:focus,
    &:-webkit-autofill:active,
    &:-webkit-autofill-strong-password,
    &:-webkit-autofill-strong-password-viewable {
      background-color: $main-black !important;
      box-shadow: 0 0 0 1000px $main-black inset !important;
      color: $main-white !important;
      -webkit-text-fill-color: $main-white;
    }

    &:-webkit-autofill-strong-password:focus,
    &:-webkit-autofill-strong-password-viewable:focus {
      border: 1px solid transparent !important;
      box-shadow: 0 0 0 3px $main-white, 0 0 0 1000px $main-black inset !important;
    }

    &:-internal-autofill-selected,
    &:-internal-autofill-previewed {
      box-shadow: 0 0 0 1000px $main-black inset !important;
      color: $main-white !important;
      font-size: 18px !important;
      -webkit-text-fill-color: $main-white;
    }

    &:-internal-autofill-selected:focus,
    &:-internal-autofill-previewed:focus {
      box-shadow: 0 0 0 2px $main-white, 0 0 0 1000px $main-black inset !important;
      color: $main-white !important;
      -webkit-text-fill-color: $main-white;
    }
  }

  &-password {
    padding: 18px 62px;
  }
}

.icon {
  left: 32px;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

.eye {
  background: transparent;
  border-style: none;
  height: 20px;
  outline: none;
  padding: 0;
  position: absolute;
  right: 32px;
  top: 50%;
  transform: translateY(-50%);
  width: 20px;
}
