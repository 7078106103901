@font-face {
  font-family: 'Sulphur Point';
  font-style: normal;
  font-weight: 400;
  src: url('../fonts/sulphur-point-v4-latin-regular.eot');
  src: local(''),
    url('../fonts/sulphur-point-v4-latin-regular.eot?#iefix')
    format('embedded-opentype'),
    url('../fonts/sulphur-point-v4-latin-regular.woff2') format('woff2'),
    url('../fonts/sulphur-point-v4-latin-regular.woff') format('woff'),
    url('../fonts/sulphur-point-v4-latin-regular.ttf') format('truetype'),
    url('../fonts/sulphur-point-v4-latin-regular.svg#SulphurPoint')
    format('svg');
}
@font-face {
  font-family: 'Sulphur Point';
  font-style: normal;
  font-weight: 700;
  src: url('../fonts/sulphur-point-v4-latin-700.eot');
  src: local(''),
    url('../fonts/sulphur-point-v4-latin-700.eot?#iefix')
    format('embedded-opentype'),
    url('../fonts/sulphur-point-v4-latin-700.woff2') format('woff2'),
    url('../fonts/sulphur-point-v4-latin-700.woff') format('woff'),
    url('../fonts/sulphur-point-v4-latin-700.ttf') format('truetype'),
    url('../fonts/sulphur-point-v4-latin-700.svg#SulphurPoint') format('svg');
}

@font-face {
  font-family: 'Sulphur Point';
  font-style: normal;
  font-weight: 300;
  src: url('../fonts/sulphur-point-v4-latin-300.eot');
  src: local(''),
    url('../fonts/sulphur-point-v4-latin-300.eot?#iefix') format('embedded-opentype'),
    url('../fonts/sulphur-point-v4-latin-300.woff2') format('woff2'),
    url('../fonts/sulphur-point-v4-latin-300.woff') format('woff'),
    url('../fonts/sulphur-point-v4-latin-300.ttf') format('truetype'),
    url('../fonts/sulphur-point-v4-latin-300.svg#SulphurPoint') format('svg');
}

@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  src: url('../fonts/inter-v3-latin-regular.eot');
  src: local(''),
    url('../fonts/inter-v3-latin-regular.eot?#iefix') format('embedded-opentype'),
    url('../fonts/inter-v3-latin-regular.woff2') format('woff2'),
    url('../fonts/inter-v3-latin-regular.woff') format('woff'),
    url('../fonts/inter-v3-latin-regular.ttf') format('truetype'),
    url('../fonts/inter-v3-latin-regular.svg#Inter') format('svg');
}
