@import 'assets/styles/variables';
@import 'assets/styles/mixins';

.title {
  @include subheading-secondary();
  color: $gray-inactive-input;
  margin-bottom: 32px;
  text-align: center;
}

.wrapper {
  margin: 0 auto;
  margin-bottom: 31px;
  position: relative;
  width: 382px;
}

.thumb {
  background: $main-black;
  border-radius: 50%;
  border-style: none;
  bottom: -12px;
  box-shadow: 0 1.77228px 4.43071px rgba(0, 0, 0, 0.25);
  color: $main-white;
  cursor: pointer;
  cursor: ew-resize;
  height: 26px;
  width: 26px;
  outline: none;

  &-dark {
    background: $main-white;
    color: $text-pure-black;
  }

  span {
    cursor: ew-resize;
    display: block;
    font-family: 'Sulphur Point';
    font-size: 14px;
    font-weight: 700;
    line-height: 26px;
    text-align: center;
    -webkit-touch-callout: none;
    user-select: none;
  }
}

.slider {
  background: rgba(217, 210, 210, 0.5);
  border-radius: 10px;
  cursor: pointer;
  display: block;
  height: 3px;
  opacity: 1;
  width: 100%;
  width: 100%;

  &-dark {
    background: rgba(151, 145, 145, 0.5);
  }
}

@media screen and (max-width: 768px) {
  .wrapper {
    width: 100%;
  }

  .title {
    color: $text-pure-black;

    &-dark {
      color: $main-white;
    }
  }

  .slider {
    background: $main-black;
    border-radius: 10px;
    cursor: pointer;
    display: block;
    height: 3px;
    opacity: 1;
    width: 100%;
    width: 100%;

    &-dark {
      background: $main-white;
    }
  }
}
