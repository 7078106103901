@import 'assets/styles/variables';
@import 'assets/styles/mixins';
@import 'assets/styles/functions';

.details {
  padding-top: 6px;
  position: relative;
  outline: none;

  summary::-webkit-details-marker {
    display: none;
  }

  summary {
    cursor: pointer;
    list-style: none;
    outline: none;
  }

  .alert {
    background-color: $main-white;
    border-radius: 14px;
    color: $text-pure-black;
    font-family: 'Inter';
    font-size: 13px;
    font-weight: 400;
    height: 156px;
    left: -492px;
    letter-spacing: -0.078px;
    line-height: 16px;
    padding: 30px 35px 20px;
    position: absolute;
    top: 44px;
    width: 270px;

    p {
      margin-bottom: 20px;
    }

    &::after {
      background-color: $main-white;
      clip-path: polygon(100% 50%, 0 0, 0 100%);
      content: '';
      display: block;
      height: 15px;
      position: absolute;
      right: -8px;
      top: 40px;
      width: 8px;
    }

    &__button {
      background: transparent;
      border-style: none;
      color: $main-black;
      cursor: pointer;
      font-family: 'Inter';
      font-size: 17px;
      font-weight: 400;
      letter-spacing: -0.408px;
      line-height: 22px;
      padding: 0;

      &:first-of-type {
        color: $blue;
        margin-right: 40px;
      }
    }
  }
}

.menu {
  background-color: $main-white;
  border-radius: 20px;
  position: absolute;
  right: 0;
  width: 247px;
  z-index: 100;

  &__item {
    @include subheading-primary();
    border-bottom: 1px solid black-opacity(0.3);
    color: $text-pure-black;
    position: relative;

    &:hover {
      background-color: $label-2-light-mode;
    }

    &:first-of-type {
      border-top-left-radius: 20px;
      border-top-right-radius: 20px;
    }

    &:last-of-type {
      border-bottom-left-radius: 20px;
      border-bottom-right-radius: 20px;
      border-bottom-style: none;
    }
  }

  &__link {
    background-position: center left 16px;
    background-repeat: no-repeat;
    background-size: 24px 24px;
    color: $text-pure-black;
    display: block;
    padding: 19px 16px 19px 56px !important;
    text-align: left;
    text-decoration: none;
    width: 100%;
  }

  .activate {
    align-items: center;
    display: flex;
    justify-content: space-between;
    padding: 19px 16px 19px 56px;
    padding-left: 16px;
    position: relative;

    div {
      margin: 0;
    }

    &::before {
      display: none;
    }
  }

  .edit {
    background-image: url('../../../assets/images/pencil.svg');
  }

  .email {
    background-image: url('../../../assets/images/email.svg');
  }

  .facebook {
    background-image: url('../../../assets/images/facebook.svg');
    background-size: 18px 18px;
  }

  .whatsapp {
    background-image: url('../../../assets/images/whatsapp.svg');
  }
}

@media screen and (max-width: 600px) {
  .menu {
    &-alert {
      visibility: hidden;
    }
  }

  .details {
    .alert {
      left: auto;
      right: 0;

      &::after {
        display: none;
      }
    }
  }
} ;
