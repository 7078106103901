@import 'assets/styles/variables';

@mixin caption-bold {
  font-family: 'Sulphur Point';
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 14px;
}

@mixin caption-regular {
  font-family: 'Sulphur Point';
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 14px;
}

@mixin subheading-primary {
  font-family: 'Sulphur Point';
  font-size: 16px;
  font-style: normal;
  font-weight: bold;
  line-height: 16px;
}

@mixin subheading-secondary {
  font-family: 'Sulphur Point';
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
}

@mixin subheading-tertiary {
  font-family: 'Sulphur Point';
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  line-height: 16px;
}

@mixin text-18-bold {
  font-family: 'Sulphur Point';
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 27px;
}

@mixin text-18-bold-caps {
  font-family: 'Sulphur Point';
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  letter-spacing: 0.1em;
  line-height: 18px;
  text-transform: uppercase;
}

@mixin text-36-bold-caps {
  font-family: 'Sulphur Point';
  font-size: 36px;
  font-style: normal;
  font-weight: 700;
  letter-spacing: 0.02em;
  line-height: 36px;
  text-transform: uppercase;
}

@mixin title-28-bold {
  color: $text-pure-black;
  font-family: 'Sulphur Point';
  font-size: 28px;
  font-style: normal;
  font-weight: 700;
  letter-spacing: 0.03em;
  line-height: 28px;
  text-align: center;
}
