@import 'assets/styles/variables';
@import 'assets/styles/mixins';

.wrapper {
  align-items: center;
  background-image: url('../../assets/images/albums-background-pink.png'),
    url('../../assets/images/albums-background-blue.png'),
    url('../../assets/images/albums-photos-collage.svg');
  background-position: bottom left, center right, top right;
  background-repeat: no-repeat;
  color: $text-pure-black;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;

  &-dark {
    background-image: url('../../assets/images/albums-background-pink.png'),
      url('../../assets/images/albums-background-blue.png'),
      url('../../assets/images/albums-photos-collage-dark.svg');
  }
}

.title {
  @include text-36-bold-caps();
  color: $text-pure-black;
  line-height: 54px;
  margin: 0 auto 40px;
  max-width: 552px;
  text-align: center;
  text-transform: none;

  &-dark {
    color: $main-white;
  }
}

.container {
  > button {
    display: block;
    margin: 0 auto;
    max-width: 338px;
  }
}

.main {
  justify-self: center;
  margin: auto;
  width: 100%;
}

@media screen and (max-width: 600px) {
  .title {
    font-size: 32px;
  }
}

@media screen and (max-width: 425px) {
  .main {
    justify-self: flex-end;
    margin-bottom: 0;
    margin-top: auto;
    padding-bottom: 40px;

    &-loading {
      justify-self: center;
      margin-bottom: auto;
    }
  }

  .container {
    > button {
      margin-left: auto;
      margin-right: 20px;
    }
  }

  .title {
    @include text-18-bold();
    margin-bottom: 140px;
    max-width: 275px;
    position: relative;

    &::after {
      background-image: url('../../assets/images/albums-arrow.svg');
      background-repeat: no-repeat;
      bottom: -150px;
      content: '';
      display: block;
      height: 130px;
      left: 0;
      margin: auto;
      position: absolute;
      right: 0;
      width: 72px;
    }

    &-dark {
      &::after {
        background-image: url('../../assets/images/albums-arrow-white.svg');
      }
    }
  }
}
