@import 'assets/styles/mixins';
@import 'assets/styles/variables';

.logo {
  padding-left: 24px;
  
  &__img {
    height: 28px;
    margin-bottom: 13px;
    width: 128px;
  }
  
  &__title {
    @include subheading-primary();
    color: $main-white;
  }
}
