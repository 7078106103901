@import 'assets/styles/variables';
@import 'assets/styles/mixins';

.link {
  display: block;
  height: 32px;
  width: 32px;

  svg {
    display: block;
    height: 32px;
    width: 32px;
  }
}
