@import 'assets/styles/variables';
@import 'assets/styles/mixins';

.back {
  cursor: pointer;
}

.main {
  align-items: center;
  position: relative;
}

.success {
  @include caption-bold();
  color: $text-pure-black;
  left: 0;
  position: absolute;
  right: 0;
  text-align: center;
  top: 100px;

  &-dark {
    color: $main-white;
  }
}
