@import 'assets/styles/mixins';
@import 'assets/styles/variables';

.container {
  align-items: center;
  display: flex;
  max-width: 370px;
  min-height: calc(100vh - #{$header-height});
  width: 100%;

  form {
    margin: 0 auto;
    max-width: 338px;
  }
}
