@import 'assets/styles/variables';
@import 'assets/styles/mixins';
@import 'assets/styles/functions';

.wrapper {
  min-height: 100vh;
  overflow: hidden;
  padding-top: $header-height;
  position: relative;
  width: 100vw;

  &-dark {
    background-color: $main-black;
  }
}

.link {
  align-self: center;
  display: block;
  height: 22px;
  width: 22px;
}

.down {
  display: block;
  height: 60px;
  margin: 0 auto;
  margin-bottom: 70px;
  width: 35px;
}

.container {
  padding-bottom: 70px;
}

.spinner {
  padding-bottom: 70px;
}

@media screen and (max-width: 768px) {
  .link {
    align-items: center;
    background-color: rgba(255, 255, 255, 0.5);
    border-radius: 50%;
    display: flex;
    height: 35px;
    padding-left: 4px;
    width: 35px;

    &-dark {
      background-color: rgba(37, 37, 37, 0.5);
    }
  }

  .down {
    display: none;
  }
}
