@import 'assets/styles/variables';
@import 'assets/styles/mixins';

.container {
  padding-bottom: 50px;
  padding-top: 10px;
}

.settings {
  display: flex;
  min-height: 551px;

  &__title {
    align-items: center;
    display: flex;
    justify-content: center;
    width: 50%;
  }

  &__input {
    @include text-36-bold-caps();
    background: transparent;
    border-style: none;
    color: $text-pure-black;
    outline: none;
    text-align: center;
    width: 100%;

    &--dark {
      color: $main-white;
    }
  }

  &__cover {
    border-radius: 20px;
    max-height: 451px;
    position: relative;
    width: 50%;
  }

  &__img {
    border-radius: 20px;
    width: 100%;

    &--dark {
      img {
        border: 3px solid $main-white;
      }
    }

    &--light {
      img {
        border: 3px solid $text-pure-black;
      }
    }

    img {
      border-radius: 20px;
      display: block;
      height: 451px;
      object-fit: cover;
      width: 100%;
    }
  }

  &__change {
    @include subheading-secondary();
    background-color: transparent;
    background-image: url('../../../assets/images/cover-photo.svg');
    background-position: center left;
    background-repeat: no-repeat;
    border-style: none;
    bottom: 90px;
    color: $main-white;
    cursor: pointer;
    height: 35px;
    left: 30px;
    padding: 0 0 0 34px;
    position: absolute;
    text-shadow: 0 2px 4px $grey-text-shadow;
    width: 189px;
  }
}

.error {
  margin-bottom: 26px;
  min-height: 34px;

  p {
    text-align: center;
  }

  &__small {
    display: none;
  }
}

@media screen and (max-width: 800px) {
  .container {
    padding-left: 0;
    padding-right: 0;
    padding-top: 0;

    > div {
      padding: 0 20px;
    }
  }

  .add {
    display: none;
  }

  .settings {
    min-height: 480px;
    position: relative;

    &__title {
      bottom: 150px;
      position: absolute;
      width: 100%;
      z-index: 10;
    }

    &__input {
      color: $main-white;
      padding-left: 30px;
      text-align: left;
      width: 100%;
    }

    &__cover {
      width: 100%;
    }

    &__img {
      border-radius: 0;
      width: 100%;

      &--dark {
        img {
          border-style: none;
        }
      }

      &--light {
        img {
          border-style: none;
        }
      }

      img {
        border-radius: 0;
        display: block;
        max-height: 451px;
        object-fit: cover;
        width: 100%;
      }
    }

    &__change {
      @include subheading-secondary();
      background-color: transparent;
      background-image: url('../../../assets/images/cover-photo.svg');
      background-position: center left;
      background-repeat: no-repeat;
      border-style: none;
      bottom: 90px;
      color: $main-white;
      cursor: pointer;
      height: 35px;
      left: 30px;
      padding: 0 0 0 34px;
      position: absolute;
      text-shadow: 0 2px 4px $grey-text-shadow;
      width: 189px;
    }
  }

  .error {
    &__big {
      display: none;
    }

    &__small {
      display: block;
    }
  }
}
