@import 'assets/styles/variables';
@import 'assets/styles/mixins';

.header {
  align-items: center;
  background-color: transparent;
  display: flex;
  min-height: $header-height;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 50;

  &-fixed {
    background-color: $main-white;
    box-shadow: 0 4px 29px rgba(228, 228, 228, 0.45);
    position: fixed;
    z-index: 100;

    details {
      position: static;
    }

    svg {
      circle {
        fill: none;
      }
    }

    &--dark {
      background-color: $main-black;
      box-shadow: 0 4px 29px rgba(0, 0, 0, 0.45);
    }
  }

  &-dark {
    background-color: $main-black;
  }

  &-transparent {
    background-color: transparent;
  }

  &-shadowed {
    box-shadow: 0 4px 29px rgba(228, 228, 228, 0.45);

    &--dark {
      background-color: $main-black;
      box-shadow: 0 4px 29px rgba(0, 0, 0, 0.45);
    }
  }
}

.container {
  align-items: center;
  display: flex;
  justify-content: space-between;

  div {
    width: 15%;
  }

  div:nth-of-type(2) {
    display: flex;
    justify-content: flex-end;
  }

  &-two-children {
    > div {
      position: absolute;
    }

    > h1 {
      align-self: center;
      margin: 0 auto;
    }
  }
}

.heading {
  color: $text-pure-black;
  font-family: 'Sulphur Point';
  font-size: 28px;
  font-weight: 700;
  letter-spacing: 0.03em;
  line-height: 28px;
  text-align: center;
  width: 70%;

  &-uppercase {
    @include text-36-bold-caps();
  }

  &-dark {
    color: $main-white;
  }

  &-transparent {
    color: transparent;
  }
}
