@import 'assets/styles/variables';
@import 'assets/styles/mixins';

.wrapper {
  padding-bottom: 50px;
  padding-top: 120px;

  .buttons {
    display: flex;
    width: auto;

    button:last-of-type {
      margin-left: 40px;
    }
  }
}

.cancel {
  @include subheading-secondary();
  background: none;
  border-style: none;
  color: $main-black;
  cursor: pointer;
  line-height: 35px;
  text-decoration: none;

  &-dark {
    color: $main-white;
  }

  &:disabled {
    cursor: not-allowed;
  }
}

.settings {
  display: none;
}

@media (max-width: 422px) {
  .wrapper {
    h1 {
      display: none;
    }
  }
}

@media screen and (max-width: 768px) {
  .wrapper {
    padding-top: 80px;

    header {
      box-shadow: none;

      h1 {
        visibility: hidden;
      }
    }

    .buttons {
      .submit {
        display: none;
      }
    }
  }

  .settings {
    background-color: $main-white;
    bottom: 0;
    display: block;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    padding: 16px 16px 37px;
    position: fixed;
    width: 100vw;
    z-index: 1000;

    &-dark {
      background-color: $main-black;
    }

    &__buttons {
      display: flex;
      justify-content: space-between;
      padding-top: 6px;
      width: 100%;

      > div {
        margin: 0;
      }
    }
  }

  .cancel {
    color: $text-pure-black;

    &-dark {
      color: $main-white;
    }
  }
}
