@import 'assets/styles/variables';
@import 'assets/styles/mixins';

.list {
  display: grid;
  grid-column-gap: 35px;
  grid-row-gap: 35px;
  grid-template-columns: repeat(auto-fit, minmax(410px, 1fr));
  justify-items: center;

  &__item {
    border-radius: 20px;
    cursor: pointer;
    height: 410px;
    overflow: hidden;
    position: relative;
    width: 410px;

    img {
      border-radius: 35px;
      display: block;
      height: 100%;
      max-height: 410px;
      object-fit: cover;
      width: 100%;
    }
  }
}

.gallery {
  display: block;

  > div {
    background: none;
    border-radius: 0;
    max-width: auto;
    padding: 0;
    width: 100%;
  }
}

@media screen and (max-width: 768px) {
  .list {
    grid-column-gap: 20px;
    grid-row-gap: 20px;
    grid-template-columns: repeat(auto-fit, minmax(114px, 1fr));

    &__item {
      border-radius: 20px;
      cursor: pointer;
      height: 114px;
      overflow: hidden;
      position: relative;
      width: 114px;

      img {
        border-radius: 20px;
        display: block;
        height: 100%;
        max-height: 114px;
        object-fit: cover;
        width: 100%;
      }
    }
  }
}
