@import 'assets/styles/variables';
@import 'assets/styles/mixins';

.error {
  @include caption-regular();
  color: $error-light-mode;
  line-height: 34px;

  &-dark {
    color: $error-dark-mode;
  }
}

@media (max-width: 422px) {
  .error {
    line-height: 14px;
  }
}
