@import 'assets/styles/fonts';

* {
  box-sizing: border-box;
  font-family: 'Sulphur Point', sans-serif;
}

:global(.image-gallery-content .image-gallery-slide .image-gallery-image) {
  padding-left: 0;
}

:global(.image-gallery) {
  min-width: 100%;
}

:global(::-webkit-textfield-decoration-container) {
  visibility: hidden !important;
}
