@import 'assets/styles/variables';
@import 'assets/styles/mixins';

.modal {
  border: 1px dashed $gray-inactive-input;
  border-radius: 12px;
  margin-bottom: 38px;
  padding: 40px 25px 32px;

  &__title {
    @include title-28-bold();
    margin-bottom: 37px;
  }

  &__subtitle {
    @include text-18-bold();
    text-align: center;
  }

  &__buttons {
    display: flex;
    justify-content: center;
    padding-top: 37px;
    width: 100%;

    button:first-of-type {
      margin-right: 25px;
    }
  }
}
