@import 'assets/styles/variables';
@import 'assets/styles/mixins';

.wrapper {
  align-items: center;
  background-image: url('../../assets/images/invite-background.png');
  background-repeat: no-repeat;
  background-size: cover;
  color: $main-white;
  display: flex;
  flex-direction: column;
  font-family: 'Sulphur Point';
  min-height: 100vh;
  padding: 100px 0 90px;
  width: 100vw;

  figure {
    padding-left: 0;
  }
}

.spinner {
  padding-top: 144px;
}

.collage {
  display: block;
  filter: drop-shadow(0 14px 31px rgba(70, 71, 157, 0.39));
  padding-top: 44px;
  width: 310px;
}

.link {
  @include text-18-bold-caps();
  background: $main-white;
  border-radius: 50px;
  color: $main-black;
  display: flex;
  height: 70px;
  justify-content: center;
  line-height: 70px;
  margin-top: 112px;
  text-decoration: none;
  width: 338px;

  &:hover {
    background: $main-black;
    color: $main-white;
  }
}

.invite {
  @include text-18-bold();
  margin-bottom: 20px;
  text-align: center;
}

.title {
  @include text-36-bold-caps();
  margin-bottom: 20px;
  text-align: center;
}

.author {
  @include caption-regular();
  text-align: center;
}
