@import 'assets/styles/variables';
@import 'assets/styles/mixins';

.content {
  border: 1px dashed $gray-inactive-input;
  border-radius: 12px;
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 37px;
  padding: 40px 25px 32px;
}

.choose {
  margin-bottom: 37px;
  width: 100%;

  img {
    display: block;
    margin: 0 auto 10px;
  }

  figcaption {
    @include title-28-bold();
  }
}

.service {
  background: transparent;
  border-radius: 50px;
  border-style: none;
  box-shadow: 0 10px 50px -20px rgba(37, 37, 37, 0.35);
  color: $main-black;
  cursor: pointer;
  display: flex;
  margin-bottom: 33px;
  outline: none;
  padding: 16px 0 16px 75px;
  width: calc(50% - 13px);

  &:hover {
    background: $button-hover-dark-mode;
  }

  &:first-of-type {
    margin-right: 25px;
  }

  &__img {
    display: block;
    margin-right: 25px;
  }

  &__caption {
    @include text-18-bold-caps();
    text-align: left;

    &--blue {
      color: $blue;
      display: block;
      letter-spacing: normal;
      text-transform: none;
    }
  }
}

.computer {
  color: $text-pure-black;
  font-family: 'Sulphur Point';
  font-size: 18px;
  font-weight: 700;
  line-height: 18px;
  text-align: center;
  width: 100%;

  &__label {
    color: $blue;
    cursor: pointer;
    position: relative;
    text-decoration: underline;
  }

  &__input {
    left: 0;
    position: absolute;
    visibility: hidden;
  }
}

.cancel {
  @include subheading-secondary();
  background: transparent;
  border-style: none;
  color: $main-black;
  cursor: pointer;
  display: block;
  margin: 0 auto;
}

@media screen and (max-width: 1000px) {
  .content {
    align-items: center;
    flex-direction: column;
  }

  .service {
    padding: 16px 35px;
    width: auto;

    &:first-of-type {
      margin-bottom: 20px;
      margin-right: 0;
    }
  }
}
