@import 'assets/styles/variables';

.main {
  background-color: $main-white;
  min-height: 100vh;
  padding-top: $header-height;

  &-dark {
    background-color: $main-black;
    color: $main-white;
  }
}
