@import 'assets/styles/variables';
@import 'assets/styles/mixins';

.wrapper {
  background-image: url('../../assets/images/albums-background-pink.png'),
    url('../../assets/images/albums-background-blue.png');
  background-position: bottom left, center right;
  background-repeat: no-repeat;
  color: $text-pure-black;
  min-height: 100vh;
  padding-top: 60px;
}

.main {
  justify-self: center;
  margin: auto;
  width: 100%;
}

.container {
  padding-bottom: 40px;
  padding-top: 70px;

  > button {
    display: block;
    margin: 0 auto;
  }
}

.title {
  @include text-36-bold-caps();
  line-height: 54px;
  margin: 0 auto 40px;
  max-width: 564px;
  text-align: center;
  text-transform: none;
}

.header {
  display: flex;
  justify-content: flex-end;
  position: relative;

  &__button {
    bottom: -8px;
    line-height: 18px;
    padding: 16px;
    position: absolute;
    right: 72px;
    width: 180px;

    > svg {
      display: none;
    }
  }

  > div {
    bottom: -8px;
    position: absolute;
    right: 162px;
    width: 180px;
  }
}

@media screen and (max-width: 700px) {
  .header {
    > div {
      bottom: 55px;
      position: fixed;
      right: 55px;
    }

    &__button {
      background-color: $main-black;
      bottom: 40px;
      font-size: 0;
      height: 70px;
      position: fixed;
      right: 40px;
      width: 70px;

      > svg {
        display: block;
        left: 0;
        margin: auto;
        right: 0;

        path {
          fill: $main-white;
        }
      }

      &-dark {
        background-color: $main-white;

        svg {
          path {
            fill: $main-black;
          }
        }
      }
    }
  }
}
