@import 'assets/styles/variables';

.wrapper {
  display: block;
  height: 31px;
  width: 51px;
}

.switcher {
  background-color: $main-black;
  border-radius: 20px;
  cursor: pointer;
  display: block;
  height: 31px;
  overflow: hidden;
  position: relative;
  width: 51px;

  &-dark {
    background-color: $main-white;
  }

  &-blue {
    .switcher__switch-checked {
      background-color: $blue;
    }
  }

  &__input {
    opacity: 0;
    position: absolute;
  }

  &__switch {
    color: $main-white;
    display: inline-block;
    height: 31px;
    left: -100%;
    position: relative;
    transform: translateX(2px);
    transition: 100ms linear;
    width: 200%;

    &-checked {
      transform: translateX(50%) translateX(-29px);
    }
  }

  &__handle {
    background: $main-white;
    border-radius: 50%;
    display: inline-block;
    height: 27px;
    left: 50%;
    position: relative;
    top: 2px;
    width: 27px;
    z-index: 3;

    &-dark {
      background: $main-black;
    }
  }
}
