@import 'assets/styles/variables';

.item {
  border-radius: 20px;
  height: 410px;
  position: relative;
  width: 410px;

  .like {
    display: none;
  }

  .delete {
    height: 35px;
    left: auto;
    right: 30px;
    top: 30px;
    width: 35px;

    &:disabled {
      cursor: not-allowed;
    }
  }

  .spinner {
    height: 35px;
    left: auto;
    right: 30px;
    top: 30px;
    width: 35px;
  }

  &-small {
    height: 160px;
    width: 160px;

    .delete {
      height: 24px;
      left: 20px;
      right: auto;
      top: 20px;
      width: 24px;
    }

    .spinner {
      height: 24px;
      left: 20px;
      right: auto;
      top: 20px;
      width: 24px;
    }

    .like {
      display: block;
    }
  }

  &-cover-mode {
    cursor: pointer;

    .like {
      display: none;
    }

    .delete {
      display: none;
    }
  }

  &-active-cover {
    box-shadow: 0 0 0 3px $text-pure-black;

    &-dark {
      box-shadow: 0 0 0 3px $main-white;
    }
  }
}

.gallery {
  display: block;

  > div {
    background: none;
    border-radius: 0;
    max-width: auto;
    padding: 0;
    width: 100%;
  }
}

.img {
  border-radius: 20px;
  height: 100%;
  object-fit: cover;
  position: absolute;
  width: 100%;
}

.delete {
  background-color: transparent;
  background-image: url('../../../assets/images/remove.svg');
  background-size: 100%;
  border-style: none;
  cursor: pointer;
  font-size: 0;
  padding: 0;
  position: absolute;
  z-index: 10;
}

.like {
  cursor: pointer;
  font-size: 0;
  height: 24px;
  position: absolute;
  right: 20px;
  top: 20px;
  width: 24px;
  z-index: 30;

  &-disabled {
    cursor: not-allowed;
  }

  input {
    position: absolute;
    visibility: hidden;
    z-index: 0;

    &:checked + svg {
      path {
        fill: #fa3737;
      }
    }
  }
}

.spinner {
  position: absolute;
  z-index: 10;
}

@media screen and (max-width: 800px) {
  .item {
    height: 114px;
    width: 114px;

    .delete {
      height: 22px;
      left: auto;
      right: 11px;
      top: 11px;
      width: 22px;
    }

    .spinner {
      height: 22px;
      left: auto;
      right: 11px;
      top: 11px;
      width: 22px;

      div {
        height: 22px !important;
        width: 22px !important;
      }
    }

    &-small {
      height: 114px;
      width: 114px;

      .delete {
        left: 11px;
        top: 11px;
      }

      .like {
        right: 11px;
        top: 11px;
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .item {
    &-small {
      height: 114px;
      width: 114px;

      .delete {
        left: 11px;
        top: 11px;
      }
    }
  }

  .like {
    right: 11px;
    top: 11px;
  }
}
