@import 'assets/styles/mixins';
@import 'assets/styles/variables';

.success {
  @include caption-bold();
  color: $main-black;
  left: 0;
  margin: 0 auto;
  max-width: 225px;
  position: absolute;
  right: 0;
  text-align: center;
  top: 100px;
}

@media screen and (max-width: 900px) {
  .success {
    top: 83px;
  }
}
