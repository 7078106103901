@import 'assets/styles/variables';
@import 'assets/styles/mixins';

.item {
  border-radius: 35px;
  height: 268px;
  overflow: hidden;
  position: relative;
  width: 310px;

  &__inactive {
    height: 28px;
    position: absolute;
    right: 30px;
    top: 30px;
    width: 28px;
  }

  &__template {
    align-items: center;
    background-color: $light-grey;
    border: 3px solid rgb(130, 130, 130);
    border-radius: 35px;
    box-sizing: border-box;
    display: flex;
    height: 100%;
    justify-content: center;
    width: 100%;
  }

  &__img {
    border-radius: 35px;
    display: block;
    height: 100%;
    max-height: 310px;
    object-fit: cover;
    width: 100%;

    &-template {
      display: block;
      height: auto;
      width: 120px;
    }
  }

  &__description {
    bottom: 35px;
    color: $main-white;
    left: 30px;
    position: absolute;
  }

  &__count {
    @include caption-bold();
    color: $label-2-dark-mode;
    margin-bottom: 23px;
  }

  &__title {
    @include text-18-bold();
    line-height: 18px;
    margin-bottom: 10px;
  }

  &__date {
    @include subheading-secondary();
    font-weight: 300;
  }
}

.list {
  display: grid;
  grid-column-gap: 20px;
  grid-row-gap: 20px;
  grid-template-columns: repeat(4, 1fr);
}

@media screen and (max-width: 1339px) {
  .list {
    align-items: center;
    grid-template-columns: repeat(3, 1fr);
  }

  .album {
    margin: 0 auto;
  }
}

@media screen and (max-width: 1100px) {
  .list {
    align-items: center;
    grid-template-columns: repeat(2, 1fr);
  }

  .album:nth-of-type(2n + 1) {
    align-self: end;
    margin-left: auto;
    margin-right: 0;
  }

  .album:nth-of-type(2n + 2) {
    align-self: end;
    margin-left: 0;
    margin-right: auto;
  }
}

@media screen and (max-width: 700px) {
  .list {
    grid-template-columns: repeat(1, 1fr);
  }

  .album:nth-of-type(2n + 1) {
    margin: 0 auto;
  }

  .album:nth-of-type(2n + 2) {
    margin: 0 auto;
  }
}
