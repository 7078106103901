@import 'assets/styles/variables';

.social {
  display: flex;
  justify-content: space-between;
  margin-bottom: 94px;
  padding-top: 80px;

  button:hover {
    background-color: $main-white;
    opacity: 0.8;
  }
}
