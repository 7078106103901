@import 'assets/styles/variables';

.button {
  background: transparent;
  border-style: none;
  cursor: pointer;
  display: block;
  font-size: 18px;
  font-weight: 700;
  line-height: 18px;
  margin-bottom: 20px;
  margin-bottom: 50px;
  padding-left: 20px;

  &-dark {
    color: $main-white;
  }
}
