.error {
  margin-bottom: 34px;
  min-height: 50px;

  p {
    text-align: center;
  }
}

.spinner {
  padding-top: 50px;
}

@media screen and (max-width: 768px) {
  .container {
    padding-bottom: 178px;
  }

  .length {
    display: none;
  }

  .add {
    display: none;
  }

  .error {
    margin-bottom: 20px;
    min-height: 35px;
  }
}
