@import 'assets/styles/variables';

.wrapper {
  align-items: center;
  background: rgba(0, 0, 0, 0.48);
  display: flex;
  height: 100vh;
  justify-content: center;
  position: fixed;
  width: 100vw;
  z-index: 10000;
}

.modal {
  background: $main-white;
  border-radius: 20px;
  max-width: 943px;
  padding: 75px 96px 41px;
  width: 100%;
}

@media screen and (max-width: 600px) {
  .modal {
    padding: 75px 6vw 41px;
  }
}
