@import 'assets/styles/variables';

.avatar {
  height: 88px;
  margin: 0 auto;
  position: relative;
  width: 88px;

  &__photo {
    border-radius: 50%;
    height: 100%;
    overflow: hidden;
    position: relative;
    width: 100%;

    img {
      border-radius: 50%;
      display: block;
      height: 100%;
      object-fit: cover;
      width: 100%;
    }
  }

  &__input {
    cursor: pointer;
    height: 100%;
    left: 0;
    opacity: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 10;

    &:disabled {
      cursor: default;
    }
  }
}
