$main-black: #252525;
$main-white: #fff;
$main-white-opacity: rgba(255, 255, 255, 0.08);
$text-pure-black: #000;
$gray-inactive-input: #979191;
$light-grey: #f2f2f2;
$label: #d9d2d2;
$label-2-light-mode: #faf8ff;
$label-2-dark-mode: #e4e4e4;
$error-light-mode: #ca0016;
$error-dark-mode: #ff8296;
$blue: #0b84ff;
$button-hover-light-mode: #0f0f0f;
$button-hover-dark-mode: #fafafa;
$grey-text-shadow: #7f7f7f;

$header-height: 80px;
$min-view-height: 900px;
