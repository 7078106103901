@import 'assets/styles/variables';
@import 'assets/styles/mixins';

.link {
  @include subheading-secondary();
  color: $main-black;
  display: inline-block;
  margin-bottom: 58px;
  padding-left: 32px;
  text-decoration: none;

  &:hover {
    opacity: 0.8;
  }
}

.signup {
  &:hover {
    opacity: 0.8;
  }
}
